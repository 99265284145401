import Head from 'next/head';
import Image from 'next/image';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import Layout from 'components/Layout';
import CustomLink from 'components/Buttons/CustomLinkComponent';

export default function Error404Page() {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Layout maxWidth="lg" showFooterCountries={false}>
      <Head>
        <title>
          Ops! Não encontramos a página que você está procurando. Erro 404 -
          Fluencypass
        </title>
      </Head>
      <Container maxWidth="lg">
        <Box my={mobileBreakpoint ? 0 : 6}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Image
                alt="imagem estudante perdido"
                height={mobileBreakpoint ? 280 : 590}
                loader={({ src, width }) => `${src}?w=${width}`}
                src="/assets/svg/travel.svg"
                title="Oops, não encontrei o que eu estava procurando!"
                width={mobileBreakpoint ? 320 : 550}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h3">
                <Box fontWeight={900}>Ops!</Box>
              </Typography>
              <Box my={2}>
                <Typography variant="h6">
                  Não encontramos a página que você está procurando. Erro 404
                </Typography>
              </Box>
              <Divider />
              <Box my={2}>
                <Typography variant="subtitle1">
                  Seguem alguns links com informações que podem ser úteis:
                </Typography>
              </Box>
              <List>
                <ListItem>
                  <CustomLink href="/" as="/">
                    <Typography variant="h6">Fluencypass Home</Typography>
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href="/intercambio" as="/intercambio">
                    <Typography variant="h6">
                      Abroad (Intercâmbio) Home
                    </Typography>
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href="/Schools" as="/schools">
                    <Typography variant="h6">Escolas</Typography>
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href="https://blog.fluencypass.com/">
                    <Typography variant="h6">Blog</Typography>
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href="/ingles-online" as="/ingles-online#faq">
                    <Typography variant="h6">Perguntas frequentes</Typography>
                  </CustomLink>
                </ListItem>
                <ListItem>
                  <CustomLink href="/About" as="/about">
                    <Typography variant="h6">Sobre a Fluencypass</Typography>
                  </CustomLink>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
}
